<template>
  <new-perseu-card title="Cancelamento de assinatura" class="text-center">
    <v-icon size="100" color="secondary">sentiment_dissatisfied</v-icon>
    <p class="body-1 mt-3">
      Ficamos muito tristes, mas esperamos te ver aqui novamente algum dia!
    </p>
    <p class="body-1">
      Você poderá continuar usando o plano normalmente até a data de validade:
      {{ $route.query.validUntil | formatDateTimeFriendly }}
    </p>
    <p class="body-1">
      Para melhorarmos a qualidade do serviço que oferecemos, por favor,
      preencha o motivo do cancelamento abaixo.
    </p>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-form ref="form">
          <v-textarea
            v-model="reason"
            outlined
            counter
            maxlength="500"
            :rules="[$rules.required]"
          />
        </v-form>
      </v-col>
    </v-row>
    <div class="d-flex flex-column justify-center align-center">
      <v-btn
        color="error"
        rounded
        large
        elevation="0"
        @click="confirmCancellation"
      >
        Confirmar cancelamento
      </v-btn>
      <v-btn
        color="secondary"
        text
        class="text-decoration-underline mt-5"
        @click="$router.go(-1)"
      >
        Não cancelar e voltar
      </v-btn>
    </div>
  </new-perseu-card>
</template>

<script>
import { cancelSubscription } from "@/services/external/paypal-service";
import { dateToString } from "@/utils/date";

export default {
  name: "Cancellation",
  data: () => ({
    reason: "",
  }),
  methods: {
    async confirmCancellation() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;

        const { data } = await cancelSubscription({ reason: this.reason });

        const message = `Assinatura cancelada com sucesso.
        Você pode continuar usando os serviços normalmente até ${dateToString(
          data.validUntil,
          "DD/MM/YYYY HH:mm"
        )}`;

        this.$successAlert({
          title: "Sucesso",
          message,
        });
        this.$router.push({ name: "subscriptions.my" });
      } catch (error) {
        this.$errorAlert({
          title: "Erro ao realizar cancelamento",
          message:
            "Entre em contato com a nossa equipe para resolvermos o mais rápido possível",
        });
        console.error(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped></style>
